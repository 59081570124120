<template>
    <section class="cont has-check">
       <!-- 面包屑 -->
       <el-row class="crumbs-box">
          <el-breadcrumb separator-class="el-icon-arrow-right">
             <el-breadcrumb-item>系统管理</el-breadcrumb-item>
             <el-breadcrumb-item>菜单管理</el-breadcrumb-item>
          </el-breadcrumb>
       </el-row>
       <el-row class="content-box">
          <!-- 查询框 -->
          <el-row class="search-box">
             <el-row class="search-row">
                <el-row class="search-item">
                   <label v-text="$t('msg.menu_name_a')">菜单：</label>
                   <el-input class="width-220"  v-model.trim="param.menuNameCode" :placeholder="$t('msg.menu_name_code')"  clearable></el-input>
                </el-row>
                <el-row class="search-item">
                   <label v-text="$t('msg.client_a')">客户端：</label>
                   <el-select class="width-220"  v-model="param.client" :placehoder="$t('msg.client')" >
                      <el-option label="全部" value=""></el-option>
                      <el-option label="web端" value="WEB"></el-option>
                      <el-option label="APP" value="APP"></el-option>
                      <el-option label="小程序" value="APPLET"></el-option>
                   </el-select>
                </el-row>
                <el-button type="primary" class="bg-gradient" @click="handleQuery(true)"><span v-text="$t('msg.search')">搜索</span></el-button>
                <el-button type="primary"  plain @click="handleQuery(false)"><span v-text="$t('msg.reset')">重置</span></el-button>
             </el-row>
          </el-row>
          <!-- 主体内容 -->
          <el-row class="table-box">
             <!-- 表格按钮 -->
             <div class="table-head-btn">
                <el-row></el-row>
                <el-row class="right-btn">
                   <el-button class="bg-gradient" type="primary"  icon="el-icon-plus"  @click="handleAdd"><span v-text="$t('msg.new_menu')"></span></el-button>
                </el-row>
             </div>
              <!-- 表格 -->
             <div class="table-box">
               <el-table
                       ref="singleTable"
                       v-loading="loading"
                       :data="tableData"
                       border fit
                       highlight-current-row
                       :stripe="true"
                       row-key="id"
                       lazy
                       :load="getMenuChild"
                       @row-click="(row) => handleTableChange(row)"
                       :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
                   <el-table-column type="selection"></el-table-column>
                   <el-table-column :label="$t('msg.menu_name')" min-width="140">
                       <template slot-scope="scope" class="active">
                           <span><i class="icon iconfont" style="color: #1ABC9C;font-size: 1.4rem;margin-right: 4px" v-html="scope.row.icon"></i>{{scope.row.name}}</span>
                       </template>
                   </el-table-column>
                   <el-table-column prop="number" :label="$t('msg.menu_code')"></el-table-column>
                   <el-table-column prop="path" :label="$t('msg.menu_path')"></el-table-column>
                   <el-table-column :label="$t('msg.type')">
                      <template slot-scope="scope">
                         <p>{{scope.row.type | filterType(that)}}</p>
                      </template>
                   </el-table-column>
                   <el-table-column :label="$t('msg.status')" min-width="70">
                      <template slot-scope="scope">
                         <i style="font-size: 1.0rem;color: #1ABC9C" :class="scope.row.state | filterState(that)"></i>
                      </template>
                   </el-table-column>
                   <el-table-column :label="$t('msg.is_it_hidden')" min-width="70">
                       <template slot-scope="scope">
                           <p style="font-size: 1.4rem;color: #1ABC9C" class="icon iconfont">{{scope.row.hideInMenu?'&#xe7f0;':''}}</p>
                       </template>
                   </el-table-column>
                   <el-table-column prop="client" :label="$t('msg.client')" min-width="70"></el-table-column>
                   <el-table-column prop="sortNo" :label="$t('msg.sort_number')" min-width="70"></el-table-column>
                   <el-table-column prop="createTime" :label="$t('msg.create_time')" min-width="130"></el-table-column>
                   <el-table-column :label="$t('msg.operating')" min-width="140" sortable fixed="right">
                       <template slot-scope="scope">
                           <el-button  type="text" @click.stop="handleEdit(scope.row)" >
                               <el-link type="primary" v-text="$t('msg.edit')">编辑</el-link>
                           </el-button>
                           <el-button type="text" style="color: #F56C6C;" @click.stop="handleDel(scope.row.id, scope.row.parentId)" >
                               <el-link type="danger" v-text="$t('msg.remove')">删除</el-link>
                           </el-button>
                           <el-button v-if="param.level === 1" v-show="scope.row.upShow" @click.stop="handleSort(scope.row.id, 'UP', scope.row.parentId)" type="text">
                               <el-link type="primary">上移</el-link>
                           </el-button>
                           <el-button v-if="param.level === 1" v-show="!scope.row.downShow" @click.stop="handleSort(scope.row.id, 'DOWN', scope.row.parentId)" type="text" style="color: #F56C6C;">
                               <el-link type="danger">下移</el-link>
                           </el-button>
                       </template>
                   </el-table-column>
               </el-table>
             </div>
             <!-- 分页 -->
             <pagination :total="total" :page-size="param.limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="handlePaging"/>
          </el-row>
           <!-- 新增/编辑弹窗 -->
          <el-dialog :title="$t('msg.new_menu')" :close-on-click-modal="false" :visible.sync="isShow" :before-close="handleClose" width="700px">
             <el-form class="form-dialog-box" :model="ruleForm" :rules="rules" ref="ruleForm">
                <el-row class="form-row">
                   <el-form-item label="所属父菜单：" v-if="action === 'add'">
                      <span>{{parentMenu || '根菜单'}}</span>
                   </el-form-item>
                </el-row>
                 <el-row class="form-row">
                    <el-form-item label="菜单名称：" required prop="name">
                       <el-input  class="width-200" v-model="ruleForm.name" :placeholder="$t('msg.menu_name')" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="菜单图标：">
                       <el-input  class="width-200" v-model="ruleForm.icon" :placeholder="$t('msg.menu_icon')" clearable></el-input>
                    </el-form-item>
                 </el-row>
                <el-row class="form-row">
                   <el-form-item label="菜单编码：" required prop="number">
                      <el-input  class="width-200" v-model="ruleForm.number" :placeholder="$t('msg.menu_code')" clearable></el-input>
                   </el-form-item>
                   <el-form-item label="客户端：" >
                      <el-select  class="width-200" v-model="ruleForm.client" :placehoder="$t('msg.client')" >
                         <el-option label="web端" value="WEB"></el-option>
                         <el-option label="APP" value="APP"></el-option>
                         <el-option label="小程序" value="APPLET"></el-option>
                      </el-select>
                   </el-form-item>
                </el-row>
                <el-row class="form-row">
                   <el-form-item label="菜单链接：">
                      <el-input  class="width-200" v-model="ruleForm.path" :placeholder="$t('msg.menu_link')" clearable></el-input>
                   </el-form-item>
                   <el-form-item label="类型：" >
                      <template>
                         <el-radio class="m-right-10" v-model="ruleForm.type" label="MENU">菜单</el-radio>
                         <el-radio v-model="ruleForm.type" label="RESOURCES">资源</el-radio>
                      </template>
                   </el-form-item>
                </el-row>
                <el-row class="form-row">
                   <el-form-item label="是否启用：" >
                      <el-switch v-model="ruleForm.state" active-value="ENABLED" inactive-value="DISABLED"></el-switch>
                   </el-form-item>
                   <el-form-item label="是否隐藏：" >
                      <el-switch v-model="ruleForm.hideInMenu" :active-value="true" :inactive-value="false"></el-switch>
                   </el-form-item>
                </el-row>
                <el-row class="form-row">
                   <el-form-item label="备注：" >
                      <el-input style="width: 300px" type="textarea"  v-model="ruleForm.remark" :placeholder="$t('msg.remark')" clearable></el-input>
                   </el-form-item>
                </el-row>
             </el-form>
             <span slot="footer" class="dialog-footer">
                <el-button  @click="resetForm" v-text="$t('msg.reset')">重置</el-button>
                <el-button class="bg-gradient" type="primary" @click="handleSave" v-text="$t('msg.save')">保存</el-button>
             </span>
          </el-dialog>
       </el-row>
    </section>
</template>

<script>
import { urlObj } from '@/api/interface'
import { system } from '@/api/interface/system'
import { CustomArray, getDict } from '@/common/js/common'
import { mapState } from 'vuex'
export default {
   data(){
      return{
         tableData: [],      // 表格数据
         maps: new Map(),    // 表格数子节点
         isShow: false,      // 是否显示弹窗
         total: 0,           // 总条数
         loading: true,      // 加载中效果
         action: 'add',       // 操作类型
         parentMenu: '',     // 父菜单名称
         parentId: '',       // 父菜单ID
         id: '',             // 菜单id
         that: this,
         param: {
            page: 1,
            limit: 1,
            level: 1,
            menuNameCode: '',  // 菜单名称/编码
            client: '',        // 客户端
         },
         ruleForm: {
            name: '',           // 菜单名称
            icon: '',           // 菜单图标
            number: '',         // 菜单编码
            client: 'WEB',      // 客户端
            path: '',           // 菜单路径
            type: 'MENU',       // 类型:菜单/资源
            state: 'ENABLED',   // 启用状态
            hideInMenu: false,  // 隐藏状态
            remark: '',         // 备注信息
         },
         rules: {
            name: [
               { required: true, message: '请输入菜单名称', trigger: 'blur' }
            ],
            number: [
               { required: true, message: '请输入菜单编码', trigger: 'blur' }
            ]
         },
         add_success: "添加成功！",
         del_success: "删除成功！",
         update_success: "修改成功！",
         sort_success: '排序成功！',
         confirm: '确定',
         cancel: '取消',
         confirm_remove: '确定移除？',
         prompt: '提示！'
      }
   },
   computed: {
      ...mapState(['dictData'])
   },
   mounted() {
      this.initForm = { ...this.ruleForm }
      this.param.limit = sessionStorage.getItem('pageSize') * 1
      getDict(['menu-type', 'menu-status'])
      this.getMenu()
   },
   methods: {
      // 获取菜单根节点列表
      getMenu(){
         const param = { ...this.param }
         const url = system.menusPaging
         this.$axios.post(url, param).then((res) => {
            if(res.success){
               this.total = res.total
               this.loading = false
               let tableData = res.records
               if (tableData.length === 0) return this.tableData = tableData
               tableData = this.IsSortShow(tableData)
               let customArray = new CustomArray(tableData)
               this.tableData = customArray.changeKey('hasChildren', 'leaf')
            }
         })
      },
      // 获取菜单子节点列表
      getMenuChild(tree, treeNode, resolve){
         // 将当前子节点数据存储到map中
         this.maps.set(tree.id, { tree, treeNode, resolve })
         const param = { parentId: tree.id, level: tree.level + 1 }
         const url = system.menu
         this.$axios.post(url, param).then(res => {
            if (res.success) {
               let childData = res.children
               if (childData.length === 0) return
               childData = this.IsSortShow(childData)
               let customArray = new CustomArray(childData)
               const newData = customArray.changeKey('hasChildren', 'leaf')
               resolve(newData)
            }
         })
      },
      // 重新获取当前子节点列表
      refreshLoadTree(parentId) {
         // 根据父级id取出对应节点数据
         if (this.maps.get(parentId)){
            const { tree, treeNode, resolve } = this.maps.get(parentId)
            this.$set(this.$refs.singleTable.store.states.lazyTreeNodeMap, parentId, [])
            if (tree) {
               this.getMenuChild(tree, treeNode, resolve)
            }
         }else {
            this.getMenu()
         }
      },
      // 搜索菜单
      handleQuery(bool){
         this.param.page = 1
         if (bool) {
            this.param.level = this.param.menuNameCode ? null : 1
            this.getMenu()
         }else {
            this.param.level = 1
            this.param.menuNameCode = ''
            this.param.client = ''
         }
      },
      // 改变显示条数
      pageChange(num){
         this.param.limit = num
         this.getMenu()
      },
      // 改变当前页
      handlePaging(num){
         this.param.page = num
         this.getMenu()
      },
      // 选中当前行
      handleTableChange: (() => {
         let id
         return function (row) {
            if (row.id !== id){
               id = row.id
               this.id = row.id
               this.parentId = row.id
               this.parentMenu = row.name
            } else {
               id = ''
               this.id = ''
               this.parentId = ''
               this.parentMenu = ''
               this.$refs.singleTable.setCurrentRow();
            }
         }
      })(),
      // 添加菜单
      handleAdd(){
         this.isShow = true
         this.action = 'add'
      },
      // 编辑菜单
      handleEdit(row){
         this.isShow = true
         this.action = 'edit'
         this.parentId = row.parentId
         this.id = row.id
         for (let k of Object.keys(this.ruleForm)) k in row && (this.ruleForm[k] = row[k])
      },
      // 重置表单
      resetForm(){
        this.ruleForm = { ...this.initForm }
      },
      // 保存菜单
      handleSave(){
         this.$refs.ruleForm.validate(valid => {
            if (!valid) return
            let url = system.AddMenu
            let param = { ...this.ruleForm }
            param.parentId = this.id
            if (this.action === 'edit'){
               url = system.editMenu
               param.id = this.id
               delete param.parentId
            }
            this.$axios.post(url, param).then(res => {
               if (res.success) {
                  this.$message({
                     showClose: true,
                     message: this.action === 'add' ? this.add_success : this.update_success,
                     type: 'success'
                  })
                  this.refreshLoadTree(this.parentId)
                  this.handleClose()
               }
            })
         })
      },
      // 关闭弹窗
      handleClose(){
         this.isShow = false
         this.resetForm()
      },
      // 删除菜单
      handleDel(id, parentId){
         this.$confirm(this.confirm_remove, this.prompt, {
            confirmButtonText: this.confirm,
            cancelButtonText: this.cancel,
            type: 'warning'
         }).then(() => {
            let ids = []; ids.unshift(id)
            const param = { ids: ids.toString() }
            const url = system.delMenu
            this.$axios.post(url, param).then(res => {
               if (res.success){
                  this.$message({
                     showClose: true,
                     message: this.del_success,
                     type: 'success'
                  })
                  parentId ? this.refreshLoadTree(parentId) : this.getMenu()
               }
            })
         })
      },
      // 显示隐藏排序按钮
      IsSortShow(tableData){
         tableData.forEach(item => {
            item.upShow = true
            item.sortNo === 1 && (item.upShow = false)
         })
         tableData[tableData.length-1].downShow = true
         return tableData
      },
      // 对菜单列表重新排序
      handleSort(id, direction, parentId){
         const param = { id: id, direction: direction }
         const url = system.menuMove
         this.$axios.post(url, param).then(res => {
            if (res.success){
               this.$message({
                  showClose: true,
                  message: this.sort_success,
                  type: 'success'
               })
               parentId ? this.refreshLoadTree(parentId) : this.getMenu()
            }
         }).catch(error => {
            this.$message.error(error)
         })
      },
   },
   filters: {
      filterType(val, that){
         if (val && that.dictData['menu-type']) return that.dictData['menu-type'][val] || val
      },
      filterState(val, that){
         if (val && that.dictData['menu-status']) return that.dictData['menu-status'][val] || val
      }
   }
}
</script>

<style lang="scss"></style>
